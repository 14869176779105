import './Footer.css'

import Social from '../Social/Social'

import vader from '../../assets/images/darth-vader.jpg'

export default function Footer () {
  return (
    <footer className="footer">
      <img src={vader} alt="" className="footer__img" />
      <div className="social-section">
        <p className="social__text">FOLLOW IT ROOM AND MTI TELECOM:</p>
        <Social />
      </div>
      <div className="info-section">
        <p className="copy">TM & © MTI Telecom Ltd.</p>
        <ul className="legal">
          <li className="legal__link"><a href="#!">Terms of Use</a></li>
          <li className="legal__link"><a href="#!">Privacy Policy</a></li>
        </ul>
      </div>
    </footer>
  )
}
